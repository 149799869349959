<template>
  <div class="card">
    <a-button
      type="dashed"
      size="large"
      icon="book"
      @click="showModal('new')"
      style="margin-bottom: 20px"
      >{{ $t("action.ajouter") }}</a-button
    >
    <!--add activity modal -->
    <a-modal
      v-model="visibleModal"
      @cancel="handleCancel"
      :title="$t('all.enterInformationBelow')"
      :width="800"
      :height="1200"
      :footer="false"
    >
      <a-form :form="form" class="container" @submit="addActivity">
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.designation')">
              <a-input
                :placeholder="$t('etablissement.designation')"
                v-decorator="[
                  'designation',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.designation'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.ordrePriorite')">
              <a-input
                :placeholder="$t('etablissement.ordrePriorite')"
                v-decorator="[
                  'order',
                  {
                    rules: [
                      {
                        required: false,
                        message: $t('requis.order'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.service')">
              <a-select
                show-search
                :autoFocus="true"
                option-filter-prop="children"
                :filter-option="filterOption"
                :placeholder="$t('etablissement.service')"
                v-decorator="[
                  'service',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('requis.service'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  :value="item._id"
                  v-for="item in activeServicesData"
                  :key="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.responsable')">
              <a-input
                :placeholder="$t('etablissement.responsableActivite')"
                v-decorator="['reponsible']"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.dateDebut')">
              <a-date-picker
                style="width: 100%"
                format="DD/MM/YYYY"
                v-decorator="[
                  'start',
                  {
                    rules: [
                      {
                        required: true,
                        message: $t('all.requiredStartDate'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.dateFin')">
              <a-date-picker
                :disabled="once"
                style="width: 100%"
                format="DD/MM/YYYY"
                v-decorator="[
                  'end',
                  {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.fraisInscri')">
              <a-input
                type="number"
                step="0.5"
                :placeholder="$t('etablissement.fraisInscri')"
                v-decorator="[
                  `inscription`,
                  {
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-2">
            <a-form-item :label="$t('etablissement.seuleFois')">
              <a-switch
                :checked="once"
                @change="trigerOnce"
                v-decorator="[`once`]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-4">
            <a-form-item :label="$t('etablissement.fraisMensuel')">
              <a-input
                type="number"
                step="0.5"
                :disabled="once"
                :placeholder="$t('etablissement.fraisMensuel')"
                v-decorator="[
                  `monthly`,
                  {
                    rules: [
                      {
                        required: !once,
                        message: $t('requis.fraisMensuel'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <a-divider>Frais des tranches</a-divider>
            <a-form-item>
              <a-row v-for="(item, index) in tranchesList" :key="index">
                <a-col :span="3"> Tranche {{ item.trancheNumber }} : </a-col>
                <a-col :span="11">
                  <a-select
                    v-model="item.months"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="Mois"
                  >
                    <a-select-option
                      v-for="i in monthRangesList"
                      :key="i"
                      :value="i"
                    >
                      {{ moment(i).format("MMMM") }}
                    </a-select-option>
                  </a-select></a-col
                >
                <a-col :span="8">
                  <a-input-number
                    style="width: 100%"
                    class="ml-2"
                    :min="0"
                    :value="item.amount"
                    v-model="item.amount"
                    placeholder="Montant"
                /></a-col>
                <a-col :span="2">
                  <a-button
                    type="dashed"
                    class="ml-4"
                    shape="circle"
                    icon="delete"
                    @click="
                      () => {
                        tranchesList.splice(index, 1);
                      }
                    "
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="14">
                  <a-button
                    type="primary"
                    class="mr-3 m-auto"
                    @click="
                      () => {
                        tranchesList.push({
                          trancheNumber: tranchesList.length + 1,
                          amount: undefined,
                          months: [],
                        });
                      }
                    "
                  >
                    Ajouter une tranche
                  </a-button>
                </a-col>
              </a-row>
            </a-form-item>
          </div>
        </div>

        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingActivity"
            :disabled="loadingActivity"
            class="mr-3"
            >{{ $t("action.ajouter") }}</a-button
          >
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingActivity"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-modal>
    <!--edit activity modal -->
    <a-drawer
      :title="$t('all.enterInformationBelow')"
      :width="720"
      :visible="visibleEditModal"
      @close="handleCancel()"
    >
      <a-form :form="updateForm" class="container" @submit="save">
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.designation')">
              <a-input
                :placeholder="$t('etablissement.designation')"
                v-decorator="[
                  'designation',
                  {
                    initialValue: selectedActivity.designation,
                    rules: [
                      {
                        required: true,
                        message: $t('requis.designation'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.ordrePriorite')">
              <a-input
                :placeholder="$t('etablissement.ordrePriorite')"
                v-decorator="[
                  'order',
                  {
                    initialValue: selectedActivity.order,

                    rules: [
                      {
                        required: false,
                        message: $t('requis.order'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.service')">
              <a-select
                show-search
                :autoFocus="true"
                option-filter-prop="children"
                :filter-option="filterOption"
                :placeholder="$t('etablissement.service')"
                v-decorator="[
                  'service',
                  {
                    initialValue: selectedActivity.service,
                    rules: [
                      {
                        required: true,
                        message: $t('requis.service'),
                      },
                    ],
                  },
                ]"
              >
                <a-select-option
                  :value="item._id"
                  v-for="item in activeServicesData"
                  :key="item._id"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.responsable')">
              <a-input
                :placeholder="$t('etablissement.responsableActivite')"
                v-decorator="[
                  'reponsible',
                  {
                    initialValue: selectedActivity.reponsible,
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.dateDebut')">
              <a-date-picker
                style="width: 100%"
                format="DD/MM/YYYY"
                v-decorator="[
                  'start',
                  {
                    initialValue: selectedActivity.start,
                    rules: [
                      {
                        required: true,
                        message: $t('all.requiredStartDate'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.dateFin')">
              <a-date-picker
                :disabled="selectedActivity.once"
                format="DD/MM/YYYY"
                style="width: 100%"
                v-decorator="[
                  'end',
                  {
                    initialValue: selectedActivity.end,
                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <a-form-item :label="$t('etablissement.fraisInscri')">
              <a-input
                type="number"
                step="0.5"
                :placeholder="$t('etablissement.fraisInscri')"
                v-decorator="[
                  `inscription`,
                  {
                    initialValue: selectedActivity.inscription,

                    rules: [
                      {
                        required: false,
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-2">
            <a-form-item :label="$t('etablissement.seuleFois')">
              <a-switch
                :checked="selectedActivity.once"
                @change="(v) => (selectedActivity.once = v)"
                v-decorator="[`once`]"
              />
            </a-form-item>
          </div>
          <div class="col-lg-4">
            <a-form-item :label="$t('etablissement.fraisMensuel')">
              <a-input
                type="number"
                step="0.5"
                :disabled="selectedActivity.once"
                :placeholder="$t('etablissement.fraisMensuel')"
                v-decorator="[
                  `monthly`,
                  {
                    initialValue: selectedActivity.monthly,

                    rules: [
                      {
                        required: !selectedActivity.once,
                        message: $t('requis.fraisMensuel'),
                      },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <a-divider>Frais des tranches</a-divider>
            <a-form-item>
              <a-row v-for="(item, index) in selectedTranches" :key="index">
                <a-col :span="3"> Tranche {{ item.trancheNumber }} : </a-col>
                <a-col :span="11">
                  <a-select
                    v-model="item.months"
                    mode="multiple"
                    style="width: 100%"
                    placeholder="Mois"
                  >
                    <a-select-option
                      v-for="i in monthRangesList"
                      :key="i"
                      :value="i"
                    >
                      {{ moment(i).format("MMMM") }}
                    </a-select-option>
                  </a-select></a-col
                >
                <a-col :span="8">
                  <a-input-number
                    type="number"
                    style="width: 100%"
                    class="ml-2"
                    :min="0"
                    :value="item.amount"
                    v-model="item.amount"
                    placeholder="Montant"
                /></a-col>
                <a-col :span="2">
                  <a-button
                    type="dashed"
                    class="ml-4"
                    shape="circle"
                    icon="delete"
                    @click="
                      () => {
                        selectedTranches.splice(index, 1);
                      }
                    "
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="8"></a-col>
                <a-col :span="14">
                  <a-button
                    type="primary"
                    class="mr-3 m-auto"
                    @click="addTranche"
                  >
                    Ajouter une tranche
                  </a-button>
                </a-col>
              </a-row>
            </a-form-item>
          </div>
        </div>
        <div class="form-actions mt-0">
          <a-button
            style="width: 150px"
            type="primary"
            htmlType="submit"
            :loading="loadingActivity"
            :disabled="loadingActivity"
            class="mr-3"
          >
            {{ $t("action.modifier") }}
          </a-button>
          <a-button
            key="back"
            @click="handleCancel"
            :disabled="loadingActivity"
            >{{ $t("action.annuler") }}</a-button
          >
        </div>
      </a-form>
    </a-drawer>
    <a-table
      :loading="tableLeading"
      :rowKey="'_id'"
      :columns="columns"
      :data-source="data"
      :pagination="true"
      :scroll="{ x: 'max-content' }"
    >
      <template slot="service" slot-scope="text, record">
        <a-select
          show-search
          :disabled="!record.editable"
          :autoFocus="true"
          style="width: 100%"
          :placeholder="$t('recette.fournisseur')"
          option-filter-prop="children"
          :filter-option="filterOption"
          @change="(e) => handleChange(e, record._id, 'service')"
          :default-value="record.service ? record.service : ''"
        >
          <a-select-option
            v-for="service in activeServicesData"
            :value="service._id"
            :key="service._id"
            >{{ service.name }}</a-select-option
          >
        </a-select>
      </template>

      <template
        v-for="col in ['designation', 'start', 'end', 'order', 'reponsible']"
        :slot="col"
        slot-scope="text, record"
      >
        <div v-if="!['end', 'start'].includes(col)" :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :type="
              col == ['inscription', 'monthly'].includes(col)
                ? 'number'
                : 'text'
            "
            :value="text"
            @change="(e) => handleChange(e.target.value, record._id, col)"
          />
          <template v-else>
            {{
              col == "monthly"
                ? !record.once
                  ? text
                  : $t("etablissement.seuleFois")
                : text
            }}
          </template>
        </div>
        <div v-else :key="col">
          <a-date-picker
            v-if="record.editable"
            :value="moment(text)"
            format="DD/MM/YYYY"
            @change="(v) => handleChange(v, record._id, col)"
          />
          <template v-else>
            <div>{{ moment(text).format("DD/MM/YYYY") }}</div>
          </template>
        </div>
      </template>
      <template slot="tranches" slot-scope="text">
        <li v-for="t in text" :key="t.trancheNumber">
          {{ "Tranche " + t.trancheNumber + " : " + t.amount }}
        </li>
      </template>
      <span slot="operation" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-button type="primary" @click="() => showModal('edit', record)">
              <a-icon type="edit" />
              {{ $t("action.modifier") }}
            </a-button>
          </span>
        </div>
      </span>
      <span slot="operationSupprimer" slot-scope="text, record">
        <div class="editable-row-operations">
          <span>
            <a-button type="danger">
              <a-popconfirm
                :title="$t('requis.supp')"
                @confirm="() => supp(record._id)"
              >
                <a>{{ $t("action.supprimer") }}</a>
              </a-popconfirm>
            </a-button>
          </span>
        </div>
      </span>
    </a-table>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import { mapState } from "vuex";
import { getRangeMonthsSY } from "@/utilities/settings";

var activitiessData = [];
export default {
  name: "activities",
  computed: mapState(["settings"]),
  beforeMount() {
    this.form = this.$form.createForm(this, { name: "activityForm" });
    this.updateForm = this.$form.createForm(this, { name: "editActivityForm" });
  },
  async created() {
    this.$gtag.pageview({
      page_path: this.$route.path,
      page_location: window.location.href,
      page_title: document.title + " - Activités",
    });
    this.monthRangesList = getRangeMonthsSY(
      this.settings.activeSchoolarYear,
      this.settings.schoolarYears
    );
    await apiClient
      .post("/services/filter", { query: {} })
      .then((res) => {
        this.services = res.data;
        this.activeServicesData = res.data.filter((e) => e.status == "active");
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.aucActivite"));
      });
    this.getActivities();
  },
  data() {
    return {
      tranchesList: [],
      monthRangesList: [],
      columns: [
        {
          title: this.$t("etablissement.service"),
          dataIndex: "service",
          key: "service",
          scopedSlots: { customRender: "service" },
          sorter: (a, b) => a.service.length - b.service.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.designation"),
          dataIndex: "designation",
          key: "designation",
          scopedSlots: { customRender: "designation" },
          sorter: (a, b) => a.designation.length - b.designation.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisInscri"),
          dataIndex: "inscription",
          key: "inscription",
          scopedSlots: { customRender: "inscription" },
          sorter: (a, b) => a.inscription - b.inscription,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.fraisMensuel"),
          dataIndex: "monthly",
          key: "monthly",
          scopedSlots: { customRender: "monthly" },
          sorter: (a, b) => a.monthly - b.monthly,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: "Tranches",
          dataIndex: "tranches",
          key: "tranches",
          scopedSlots: { customRender: "tranches" },
        },
        {
          title: this.$t("etablissement.responsable"),
          dataIndex: "reponsible",
          key: "reponsible",
          scopedSlots: { customRender: "reponsible" },
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("etablissement.dateDebut"),
          dataIndex: "start",
          key: "start",
          scopedSlots: { customRender: "start" },
        },
        {
          title: this.$t("etablissement.dateFin"),
          dataIndex: "end",
          key: "end",
          scopedSlots: { customRender: "end" },
        },
        {
          title: this.$t("etablissement.order"),
          dataIndex: "order",
          key: "order",
          scopedSlots: { customRender: "order" },
          sorter: (a, b) => a.order.length - b.order.length,
          sortDirections: ["descend", "ascend"],
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "operationSupprimer",
          scopedSlots: { customRender: "operationSupprimer" },
          fixed: "right",
        },
      ],
      services: [],
      activeServicesData: [],
      once: true,
      tableLeading: true,
      visibleModal: false,
      visibleEditModal: false,
      form: this.$form.createForm(this),
      updateForm: this.$form.createForm(this),
      data: null,
      cacheData: null,
      loadingActivity: false,
      editingKey: "",
      selectedActivity: {},
      selectedTranches: [],
    };
  },
  methods: {
    async getActivities() {
      await apiClient
        .post("/activities/filter", {
          query: {
            status: "active",
          },
          aggregation: {
            $project: {
              __v: 0,
              updatedAt: 0,
              teachers: 0,
            },
          },
        })
        .then((res) => {
          activitiessData = [...res.data];
          activitiessData = activitiessData.map((elem) => {
            var sc = "";
            try {
              if (
                this.services.find((e) => e._id === elem.service).status ===
                "inactive"
              )
                elem.service = "--";
              sc = this.settings.schoolarYears.filter(
                (s) => s._id == elem.schoolarYear
              )[0]._id;
            } catch {
              sc = "";
            }
            return {
              ...elem,
              key: elem._id,
              schoolarYear: sc,
            };
          });
          this.data = activitiessData;
          this.cacheData = activitiessData.map((item) => ({ ...item }));
          this.tableLeading = false;
        })
        .catch((e) => this.$message.error(this.$t("error.aucActivite")));
    },
    addTranche() {
      this.selectedTranches.push({
        trancheNumber: this.selectedTranches.length + 1,
        amount: undefined,
        months: [],
      });
    },
    trigerOnce(v) {
      this.once = v;
    },
    moment(any) {
      return moment(any);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    addActivity(e) {
      e.preventDefault();
      const thereEmpty = this.tranchesList.filter(
        (s) => !s.trancheNumber || !s.amount
      );

      if (thereEmpty.length > 0) {
        this.$message.warning("Vérifier les frais des tranches");
        return;
      }
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          this.loadingActivity = true;
          values.tranches = this.tranchesList;

          let data = { ...values };
          data.once = this.once;
          if (this.once && values.monthly) delete values.monthly;
          apiClient
            .put("/activities", { data: data })
            .then((res) => {
              this.$message.success(this.$t("success.activiteAjout"));
              res.data.key = res.data._id;
              this.data.push(res.data);
              this.cacheData.push(res.data);
              this.handleCancel();
            })
            .catch((e) =>
              this.$message.error(this.$t("error.impoAjoutActivites"))
            )
            .finally(() => (this.loadingActivity = false));
        }
      });
    },
    showModal(type = "new", record = null) {
      this.loadingActivity = false;
      if (type == "new") this.visibleModal = true;
      else {
        this.selectedActivity = { ...record };
        if (!this.selectedActivity.tranches) this.selectedTranches = [];
        else this.selectedTranches = this.selectedActivity.tranches;
        this.visibleEditModal = true;
      }
    },
    handleCancel() {
      this.form.resetFields();
      this.visibleModal = false;
      this.visibleEditModal = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.data];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    save(e) {
      e.preventDefault();
      this.updateForm.validateFieldsAndScroll((err, values) => {
        this.loadingActivity = true;
        if (!err) {
          const updates = values.once
            ? {
                service: values.service,
                designation: values.designation,
                inscription: values.inscription,
                once: values.once,
                monthly: values.monthly,
                order: values.order,
                start: values.start,
                end: values.end,
                reponsible: values.reponsible,
                tranches: this.selectedTranches,
              }
            : {
                service: values.service,
                designation: values.designation,
                inscription: values.inscription,
                once: values.once,
                monthly: values.monthly,
                order: values.order,
                start: values.start,
                end: values.end,
                reponsible: values.reponsible,
                tranches: this.selectedTranches,
              };
          apiClient
            .patch("/activities/" + this.selectedActivity._id, {
              data: updates,
            })
            .then(() => {
              this.$message.success(this.$t("success.activiteMSA"));
              this.handleCancel();
              this.getActivities();
            })
            .catch((e) => {
              console.error(e);
              this.$message.error(this.$t("error.activiteNonMSA"));
            })
            .finally(() => (this.loadingActivity = false));
        } else {
          console.error(err);
        }
      });
    },

    supp(key) {
      apiClient
        .patch("/activities/" + key, { data: { status: "inactive" } })
        .then(() => {
          this.$message.success(this.$t("success.activiteSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item.key !== key);
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.activiteNonSupp"));
        });
    },
  },
};
</script>
<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
